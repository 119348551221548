import React, { Component } from 'react';
import { Big } from 'big.js';
import slugify from 'slugify';
import { BiBitcoin } from 'react-icons/bi';
import { BsArrowRepeat } from 'react-icons/bs';
import { FaCheckCircle, FaDollarSign } from 'react-icons/fa';
import {
  Link,
  withTranslation,
  WithTranslation,
} from 'gatsby-plugin-react-i18next';

/** Images */
import Shrimp from '@images/shrimp.png';
import Whale from '@images/whale.png';
import Shark from '@images/shark.png';

/** Services */
import { ExchangeRatesService } from '@services/exchange-rates.service';

type State = {
  usdToEurRate: number;
};

class DcaSectionComponent extends Component<WithTranslation, State> {
  private readonly ratesService: ExchangeRatesService;
  private readonly classicDcaPrice = 4.99;
  private readonly smartDcaPrice = 7.98;
  private readonly frenchVatRate = 20;

  constructor(props: Readonly<WithTranslation>) {
    super(props);
    this.ratesService = new ExchangeRatesService();

    this.state = {
      usdToEurRate: 0,
    };

    this.getRates();
  }

  private async getRates(): Promise<void> {
    const result = await this.ratesService.getRates();

    if (!result) {
      return;
    }

    this.setState({
      usdToEurRate: result.rates.EUR,
    });
  }

  private renderEurPriceLabel(price: number): JSX.Element | null {
    const { usdToEurRate } = this.state;
    const { t } = this.props;

    return usdToEurRate > 0 ? (
      <div className="d-flex justify-content-center mb-3 mt-0 align-items-end">
        <span className="text-muted">
          (≈
          {'\u00a0' +
            t('home.dca.eurPrice', {
              price: Number(
                Big(this.getPriceWithFrenchVatRate(price))
                  .times(usdToEurRate)
                  .toFixed(2),
              ),
              locale: 'fr-FR',
            })}
          )
        </span>
      </div>
    ) : null;
  }

  private getPriceWithFrenchVatRate(priceVatOff: number): number {
    return Number(
      Big(priceVatOff)
        .plus(Big(this.frenchVatRate).times(priceVatOff).div(100))
        .toFixed(2),
    );
  }

  render(): JSX.Element {
    const { t } = this.props;
    const id = slugify(t('home.dca.recurringInvestment').toLowerCase());

    return (
      <section
        id={id}
        className="row m-0 bg-custom-grey home-section fs-section pb-5"
      >
        <div className="container-xxl">
          <div className="col-sm-12">
            <h2 className="title-section text-primary fw-bold text-center">
              {t('home.dca.recurringInvestment')}
            </h2>
            <h4 className="card-subtitle text-muted mt-2 text-center">
              {t('home.dca.subTitle')}
            </h4>

            <div className="row mt-5">
              <div className="col-xxl-5 col-xl-12 ps-5">
                <div className="row pe-5 mb-5">
                  <strong>{t('home.dca.desc1')}</strong>
                  <div>{t('home.dca.desc2')}</div>
                </div>

                <h3 className="fw-bold h3">{t('home.global.title')}</h3>
                <div className="d-flex align-items-center mt-3">
                  <div className="icon-circle-background">
                    <BiBitcoin color={'#F29F05'} className="m-3" />
                  </div>
                  <div className="ps-4">
                    <strong>{t('home.dca.selectPair')}</strong>
                    <div>{t('home.dca.selectPairInfos')}</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <div className="icon-circle-background">
                    <BsArrowRepeat color={'#F29F05'} className="m-3" />
                  </div>
                  <div className="ps-4">
                    <strong>{t('home.dca.selectRecurrence')}</strong>
                    <div>{t('home.dca.selectRecurrenceInfos')}</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <div className="icon-circle-background">
                    <FaDollarSign color={'#F29F05'} className="m-3" />
                  </div>
                  <div className="ps-4">
                    <strong>{t('home.dca.selectAmount')}</strong>
                    <div>{t('home.dca.selectAmountInfos')}</div>
                  </div>
                </div>
                <p className="mt-5">
                  <Link
                    className="btn btn-primary text-body ps-5 pe-5 fw-bold"
                    to="/dca-simulator"
                  >
                    {t('home.dca.simulate')}
                  </Link>
                </p>
                <p className="mt-4">{t('home.dca.simulateInfo')}</p>
              </div>

              <div className="col">
                <div className="row ps-2 pe-2">
                  <div className="col-12 col-lg-4 ps-0 pe-0 d-flex">
                    <div className="dca-column mb-5 mt-5 ms-2 p-0 w-100">
                      <img src={Shrimp} className="dca-images" />

                      <h3 className="fw-bold h3 ps-4 pe-4 pb-1 mb-4 text-center">
                        {t('home.dca.classic')}
                      </h3>
                      <hr />
                      <div className="d-flex justify-content-center mt-4 align-items-end">
                        <h2 className="fw-bold mb-0">
                          {t('home.dca.price', {
                            price: this.getPriceWithFrenchVatRate(
                              this.classicDcaPrice,
                            ),
                            locale: 'en-US',
                          })}
                        </h2>
                        <span className="fw-bold ms-2">
                          {t('home.dca.perMonth')}
                        </span>
                      </div>
                      {this.renderEurPriceLabel(this.classicDcaPrice)}
                      <div className="m-0 p-2">
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info1')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info2')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info3')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info4')}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 ps-0 pe-0 pb-1 ps-lg-2 pe-lg-2">
                    <div className="dca-column p-0 pb-0 pb-0 pb-xl-5">
                      <div>
                        <img src={Shark} className="dca-images" />
                      </div>
                      <div className="d-flex justify-content-center mt-1 mb-1">
                        <div className="row badge text-primary light-orange-bg-color mb-3">
                          {t('home.dca.new')}
                        </div>
                      </div>
                      <h3 className="fw-bold h3 ps-4 pe-4 pb-1 mb-4 text-center">
                        {t('home.dca.smart')}
                      </h3>
                      <hr />
                      <div className="d-flex justify-content-center mt-4 align-items-end">
                        <h2 className="fw-bold mb-0 mt-2">
                          {t('home.dca.price', {
                            price: this.getPriceWithFrenchVatRate(
                              this.smartDcaPrice,
                            ),
                            locale: 'en-US',
                          })}
                        </h2>
                        <span className="fw-bold ms-2">
                          {t('home.dca.perMonth')}
                        </span>
                      </div>
                      {this.renderEurPriceLabel(this.smartDcaPrice)}
                      <div className="m-0 p-2">
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info1')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info2')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info3')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info4')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">
                            {t('home.dca.classicDcaIncluded')}
                          </p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">
                            {t('home.dca.tunedEntryPoints')}
                          </p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">
                            {t('home.dca.technicalIndicatorBased')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 ps-0 pe-0 pb-1 pe-lg-2 mt-5 mt-lg-0">
                    <div className="dca-column p-0 pb-0 pb-0 pb-xl-5 h-100">
                      <img src={Whale} className="dca-images" />
                      <div className="d-flex justify-content-center mt-1 mb-1">
                        <div className="row badge text-primary light-orange-bg-color mb-3">
                          {t('home.dca.new')}
                        </div>
                      </div>
                      <h3 className="fw-bold h3 ps-4 pe-4 pb-1 mb-4 text-center">
                        {t('home.dca.smartDip')}
                      </h3>
                      <hr />
                      <div className="d-flex justify-content-center mb-0 mt-4 align-items-end">
                        <h2 className="fw-bold mb-0 mt-2">
                          {t('home.dca.price', {
                            price: this.getPriceWithFrenchVatRate(
                              this.smartDcaPrice,
                            ),
                            locale: 'en-US',
                          })}
                        </h2>
                        <span className="fw-bold ms-2">
                          {t('home.dca.perMonth')}
                        </span>
                      </div>
                      {this.renderEurPriceLabel(this.smartDcaPrice)}
                      <div className="m-0 p-2">
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info1')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info2')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info3')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">{t('home.dca.info4')}</p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">
                            {t('home.dca.smartDcaIncluded')}
                          </p>
                        </div>
                        <div className="row">
                          <div className="col check-circle ms-1">
                            <FaCheckCircle color={'green'} size="14px" />
                          </div>
                          <p className="col ps-0">
                            {t('home.dca.smartDipDesc')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const DcaSection = withTranslation()(DcaSectionComponent);

export default DcaSection;
