import React, { useState } from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from 'slugify';
import { Doughnut } from 'react-chartjs-2';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FaBitcoin, FaCreditCard, FaEthereum, FaPaypal } from 'react-icons/fa';
import { Chart as ChartJS, ArcElement } from 'chart.js';

ChartJS.register(ArcElement);

/** SVG */
import DollarSVG from './svg/dollar.svg';
import LinkSVG from './svg/link.svg';
import ProfitsSVG from './svg/profits.svg';
import LiteCoinSvg from './svg/litecoin';

/** Root */
import { getPathByLanguage } from '@root/util';
import { FaqUrlResolver } from '@root/faq-url-resolver';

const BotsSection = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const id = slugify(t('home.bots.automatedInvestment').toLowerCase());
  const [, setClickedAnchorPath] = useState('');

  const currentLang = i18n.language;

  const testimonialPath = getPathByLanguage(
    currentLang,
    `/#${slugify(t('navbar.testimonials').toLowerCase())}`,
  );

  const chartRef = React.createRef();
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };
  const data = {
    labels: [],
    datasets: [
      {
        data: [60, 10, 30],
        backgroundColor: ['#F29F05', '#3283FC', '#0046B0'],
      },
    ],
  };

  return (
    <section id={id} className="row m-0 bg-white home-section fs-section pb-5">
      <div className="container-xxl">
        <div>
          <div className="row bg-transparent border-0">
            <h2 className="title-section text-primary fw-bold text-center">
              {t('home.bots.automatedInvestment')}
            </h2>
          </div>

          <div className="row mt-5">
            <div className="col-12 col-lg-5 ps-md-5">
              <h3 className="fw-bold h3">{t('home.global.title')}</h3>
              <h4 className="card-subtitle text-muted mt-2">
                {t('home.bots.subTitle')}
              </h4>

              <div className="d-flex align-items-center mt-5">
                <div className="icon-circle-background">
                  <LinkSVG className="m-3" height={30} width={30} />
                </div>
                <div className="ps-4">
                  <strong>{t('home.bots.register')}</strong>
                  <div>
                    {t('home.bots.registerInfos1')}
                    <strong>{t('home.bots.registerInfosExchangeName1')}</strong>
                    {t('home.bots.registerInfos2')}
                    <strong>{t('home.bots.registerInfosExchangeName2')}</strong>
                    {t('home.bots.registerInfos3')}
                    <strong>{t('home.bots.registerInfosExchangeName3')}</strong>
                    {t('home.bots.registerInfos4')}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center mt-4">
                <div className="icon-circle-background">
                  <DollarSVG className="m-3" height={30} width={30} />
                </div>
                <div className="ps-4">
                  <strong>{t('home.bots.amount')}</strong>
                  <div>{t('home.bots.amountInfos')}</div>
                </div>
              </div>

              <div className="d-flex align-items-center mt-4">
                <div className="icon-circle-background">
                  <ProfitsSVG className="m-3" height={30} width={30} />
                </div>
                <p className="ps-4 mt-3">
                  <strong>{t('home.bots.benefits')}</strong>
                </p>
              </div>

              <p className="mt-5">
                <Link
                  className="btn btn-primary text-body ps-5 pe-5 fw-bold"
                  to="/register"
                >
                  {t('navbar.register')}
                </Link>
              </p>

              <p className="mt-4">
                {t('home.bots.unsure')}
                <AnchorLink
                  className="ms-2 text-muted"
                  to={testimonialPath}
                  onAnchorLinkClick={() =>
                    setClickedAnchorPath(testimonialPath as string)
                  }
                >
                  {t('home.bots.readTestimonials')}
                </AnchorLink>
              </p>
            </div>

            <div className="col-12 col-lg-7 pe-md-5 mt-4 mt-md-4 mt-lg-0">
              <div className="bg-light row offset-md-2 card-background">
                <div className="d-flex flex-md-row  mb-5 flex-column align-items-center">
                  <div className="mt-5 bot-text order-1 order-md-2 pe-4">
                    <h3 className="fw-bold h3">{t('home.global.howMuch')}</h3>

                    <p className="mt-4">
                      <strong>{t('home.bots.payOnlyBenefits')}</strong>
                    </p>
                    <p>{t('home.bots.paymentDetails')}</p>
                    <p>
                      <span>{t('home.invoicing.refPlus')}</span>
                      <strong>{t('home.invoicing.refInviteFriends')}</strong>
                      <span>{t('home.invoicing.refPercent')}</span>
                      <strong>{t('home.invoicing.refCoverAll')}</strong>
                    </p>
                    <p>
                      <a
                        href={FaqUrlResolver.get('invoicing', i18n.language)}
                        target="_blank"
                        rel="noreferrer"
                        className="text-body"
                      >
                        {t('home.invoicing.moreInformation')}
                      </a>
                    </p>
                    <p className="mt-5">
                      <FaCreditCard size="30px" color="grey" className="me-3" />
                      <FaPaypal
                        size="30px"
                        color="grey"
                        className="ms-1 me-3"
                      />
                      <FaBitcoin
                        size="30px"
                        color="grey"
                        className="ms-1 me-3"
                      />
                      <FaEthereum
                        size="30px"
                        color="grey"
                        className="ms-1 me-3"
                      />
                      <LiteCoinSvg
                        height={30}
                        width={30}
                        fill={'grey'}
                        className="ms-1"
                      />
                    </p>
                  </div>
                  <div className="graphic order-2 order-md-1">
                    <Doughnut
                      width={1}
                      height={1}
                      ref={chartRef as any}
                      data={data}
                      options={options as any}
                    />
                  </div>
                </div>
              </div>

              <div className="row gx-0 d-flex mt-2 graphic-caption position-relative align-items-center">
                <div className="offset-md-3 d-flex col-12 col-md-3 align-items-center justify-content-center">
                  <div className="initial-amount dot"></div>
                  <p className="small ms-2 mt-3">
                    {t('home.invoicing.initAmount')}
                  </p>
                </div>
                <div className="d-flex col-12 col-md-3 align-items-center justify-content-center">
                  <div className="whalinvest-fees dot"></div>
                  <p className="small ms-2 mt-3">
                    {t('home.invoicing.whaliCost')}
                  </p>
                </div>
                <div className="d-flex col-12 col-md-3 align-items-center justify-content-center">
                  <div className="benefits dot"></div>
                  <p className="small ms-2 mt-3">
                    {t('home.invoicing.benefits')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BotsSection;
