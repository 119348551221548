import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';

/** SVG */
import { SimplicitySVG } from './svg/simplicity.svg';
import { SerenitySVG } from './svg/serenity.svg';
import { TransparencySVG } from './svg/transparency.svg';

const OurValuesSection = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section className="bg-primary home-section fs-section pb-5">
      <div className="row m-0">
        <div className="col-sm-12 mt-5 text-center">
          <h2 className="fw-bold title-section mb-4">
            {t('home.companyValues.title')}
          </h2>
        </div>
      </div>
      <div className="row mt-5 me-0">
        <div className="col-sm-4 ps-5 pe-5">
          <ScrollAnimation animateIn="animate__fadeIn" delay={100} animateOnce>
            <section className="text-center">
              <SimplicitySVG height={70} width={70} />
            </section>
            <section className="text-center mt-5">
              <h3 className="mb-5 h2">{t('home.companyValues.simplicity')}</h3>
              <p>{t('home.companyValues.simplicityP1')}</p>
              <p>
                <strong>{t('home.companyValues.simplicityP2Strong')}</strong>
                <span>{t('home.companyValues.simplicityP2')}</span>
              </p>
              <p>{t('home.companyValues.simplicityP3')}</p>
            </section>
          </ScrollAnimation>
        </div>
        <div className="col-sm-4 ps-5 pe-5">
          <ScrollAnimation animateIn="animate__fadeIn" delay={300} animateOnce>
            <section className="text-center">
              <SerenitySVG height={70} width={70} />
            </section>
            <section className="text-center mt-5">
              <h3 className="mb-5 h2">{t('home.companyValues.serenity')}</h3>
              <p>{t('home.companyValues.serenityP1')}</p>
              <p>
                <strong>{t('home.companyValues.serenityP2')}</strong>
              </p>
              <p>{t('home.companyValues.serenityP3')}</p>
            </section>
          </ScrollAnimation>
        </div>
        <div className="col-sm-4 ps-5 pe-5">
          <ScrollAnimation animateIn="animate__fadeIn" delay={600} animateOnce>
            <section className="text-center">
              <TransparencySVG height={70} width={70} />
            </section>
            <section className="text-center mt-5">
              <h3 className="mb-5 h2">
                {t('home.companyValues.transparency')}
              </h3>
              <p>{t('home.companyValues.transparencyP1')}</p>
              <p>
                <strong>{t('home.companyValues.transparencyP2')}</strong>
              </p>
              <p>{t('home.companyValues.transparencyP3')}</p>
            </section>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
};

export default OurValuesSection;
