import React, { useEffect, useState, useRef } from 'react';

/** Root */
import { getWindow } from '@root/util';

/** Svg */
import ArrowDown from './svg/arrow-down';

const triggerDetection = 20;
const intervalRepeat = 8000;
const timeoutReset = 1500;
const defaultClassName = [
  'p-2',
  'rounded-circle',
  'position-fixed',
  'bottom-0',
  'mb-2',
];

const ScrollDownIndicator = (): JSX.Element => {
  const [visible, setVisible] = useState(true);
  const [className, setClassName] = useState(defaultClassName);
  const [startX, setStartX] = useState(0);
  const window = getWindow();
  const scrollSpyHandler = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const minTrigger = scrollHeight - triggerDetection;
    const trigger = scrollTop + clientHeight;

    if (trigger >= minTrigger) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  const elementRef = useRef<HTMLDivElement | null>(null);
  const resizeHandler = () => {
    const rect = elementRef.current?.getBoundingClientRect();

    if (rect) {
      const newStartX = window.innerWidth / 2 - rect.width / 2;

      setStartX(newStartX);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollSpyHandler);
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    const intervalId = setInterval(() => {
      const updatedClass = defaultClassName.concat([
        'animate__animated',
        'animate__bounce',
      ]);

      setClassName(updatedClass);
      setTimeout(() => setClassName(defaultClassName), timeoutReset);
    }, intervalRepeat);

    return () => {
      window.removeEventListener('scroll', scrollSpyHandler);
      window.removeEventListener('resize', resizeHandler);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className={className.join(' ')}
      ref={elementRef}
      style={{
        display: visible ? 'block' : 'none',
        left: startX,
      }}
    >
      <ArrowDown />
    </div>
  );
};

export default ScrollDownIndicator;
