import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import {
  Link,
  withTranslation,
  WithTranslation,
} from 'gatsby-plugin-react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Slider from 'react-slick';

/** Components */
import Seo from '@components/seo';
import BotsSection from '@components/bot.section';
import DcaSection from '@components/dca.section';
import MainContainer from '@components/main.container';
import ScrollDownIndicator from '@components/scroll-down.indicator';
import OurValuesSection from '@components/our-values.section';
import CommunitySection from '@components/community.section';
import TestimonialSection from '@components/testimonial.section';
import RobotSvg from '@components/svg/robot.svg';

/** Models */
import { BackResponse } from '@models/back-response.model';

/** Root */
import { asyncWrap } from '@root/util';

type State = {
  lastTransaction: Array<{ pair: string; percents: number }>;
};

class HomeComponent extends Component<WithTranslation, State> {
  constructor(props: Readonly<WithTranslation>) {
    super(props);

    this.state = { lastTransaction: [] };
  }

  componentDidMount() {
    this.lastTransactions();
  }

  private async lastTransactions() {
    const requestGetTransactions = await asyncWrap<
      AxiosResponse<BackResponse<Array<{ pair: string; percents: number }>>>,
      AxiosError
    >(axios.get(`${process.env.GATSBY_API_URL}/transaction/last`));

    if (requestGetTransactions.error) {
      return;
    }

    const { data: response } = requestGetTransactions.result as AxiosResponse<
      BackResponse<Array<{ pair: string; percents: number }>>
    >;

    this.setState({ lastTransaction: response.data as any });
  }

  private getCarouselTransactions(): JSX.Element | null {
    const { lastTransaction } = this.state;

    if (!lastTransaction.length) {
      return null;
    }

    const { t } = this.props;

    return (
      <section className="section-last-profits bg-dark-grey pt-3">
        <h4 className="text-primary text-center mb-4">
          {t('home.lastProfits')}
        </h4>
        <Slider
          infinite
          autoplay
          arrows={false}
          speed={30000}
          slidesToShow={5}
          slidesToScroll={3}
          draggable={false}
          pauseOnHover={false}
          cssEase="linear"
          className="text-center"
          responsive={[
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                speed: 10000,
              },
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                speed: 20000,
              },
            },
          ]}
        >
          {lastTransaction.map(
            (transaction: { pair: string; percents: number }) => {
              const formattedPair = transaction.pair.replace('/', '');
              const formattedPercent = `${transaction.percents}`.replace(
                '.',
                '',
              );
              const hashBot =
                `${formattedPair}${formattedPercent}`.toLowerCase();

              return (
                <Fragment key={hashBot}>
                  <RobotSvg height={22} width={22} className="text-primary" />
                  <span className="text-white align-middle ms-2">
                    {transaction.pair} : {transaction.percents}%
                  </span>
                </Fragment>
              );
            },
          )}
        </Slider>
      </section>
    );
  }

  render(): JSX.Element {
    const { t } = this.props;

    return (
      <Fragment>
        <Seo />
        <MainContainer>
          <Fragment>
            <section id="head" className="row m-0 home-section">
              <div
                id="stocks-home"
                className="col-sm-12 fs-section d-flex flex-column text-center align-items-center justify-content-center"
              >
                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={300}
                  animateOnce
                >
                  <p id="jumbotron" className="fw-bold">
                    {t('seo.defaultDescription')}
                  </p>
                  <p className="sub-jumbotron mb-0">{t('home.subJumbotron')}</p>
                  <p className="sub-jumbotron">{t('home.subJumbotron2')}</p>
                  <Link
                    className="btn btn-primary text-body btn-lg"
                    to="/register"
                  >
                    {t('home.tryIt')}
                  </Link>
                </ScrollAnimation>
              </div>
            </section>
            {this.getCarouselTransactions()}
            <BotsSection />
            <DcaSection />
            <OurValuesSection />
            <TestimonialSection />
            <CommunitySection />
          </Fragment>
          <ScrollDownIndicator />
        </MainContainer>
      </Fragment>
    );
  }
}

const Home = withTranslation()(HomeComponent);

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
