import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
};

const defaultWidth = 128;
const defaultHeight = 128;
const defaultColor = '#fff';

const LiteCoinSvg = (props: Props): JSX.Element => {
  const { width, height, className, fill } = props;
  const appliedWith = width ? width : defaultWidth;
  const appliedHeight = height ? height : defaultHeight;
  const appliedColor = fill ? fill : defaultColor;
  const appliedClassName = className ? className : '';

  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={appliedWith}
      height={appliedHeight}
      className={appliedClassName}
      viewBox="0 0 82.6 82.6"
    >
      <circle cx="41.3" cy="41.3" r="36.83" fill="#ffffff00" />
      <path
        d="M41.3 0a41.3 41.3 0 1041.3 41.3A41.18 41.18 0 0041.54 0zm.7 42.7l-4.3 14.5h23a1.16 1.16 0 011.2 1.12v.38l-2 6.9a1.49 1.49 0 01-1.5 1.1H23.2l5.9-20.1-6.6 2L24 44l6.6-2 8.3-28.2a1.51 1.51 0 011.5-1.1h8.9a1.16 1.16 0 011.2 1.12v.38l-7 23.8 6.6-2-1.4 4.8z"
        fill={appliedColor}
      />
    </svg>
  );
};

export default LiteCoinSvg;
