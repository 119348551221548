import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

const defaultWidth = 128;
const defaultHeight = 128;

export const SerenitySVG = (props: Props): JSX.Element => {
  const { width, height, className } = props;
  const appliedWith = width ? width : defaultWidth;
  const appliedHeight = height ? height : defaultHeight;
  const appliedClassName = className ? className : '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={appliedWith}
      height={appliedHeight}
      viewBox="0 0 81 78"
      className={appliedClassName}
    >
      <path
        fill="#2E2E2E"
        d="M40 .6c5.5 0 10 4.5 10 10 0 2.7-1 5.2-2.9 7.1-1.9 1.9-4.4 2.9-7.1 2.9-5.5 0-10-4.5-10-10s4.5-10 10-10m-.7 2.6c-3.7.4-6.6 3.5-6.6 7.3 0 4.1 3.3 7.4 7.4 7.4 4.1 0 7.4-3.3 7.4-7.4S44.1 3.2 40 3.2h-.7M38.4 23.8H40.2c2.5 0 15.1.4 19 8.3 4.9 9.7 11.1 16.7 15.6 17.3 1.6.2 2.9.9 3.6 1.9.6.8.9 1.9.8 2.9-.2 1.4-1.1 2.7-2.3 3.5-1.1.7-2.4 1.1-3.8 1.1-.4 0-.8 0-1.1-.1-2.8-.4-10.1-2.4-16.9-11.9L53.8 45l-.1 2.2c-.2 4.6.5 10.7 4.4 14.3.1.1.2.2.2.3l.2.4h.4c1.5-.1 2.7-.1 3.7-.1 3.5 0 7.7.5 9 4.1 1.2 3.1.5 5.1-.3 6.3-1.7 2.5-5.6 3.8-11.8 3.8-5.2-.1-10.4-.7-15.5-1.7h-.2l-.2.1c-.1.1-.3.1-.4.1l-3.8.3c-7.4.3-13.7.9-18.7.9-6.4 0-10.1-.9-12.1-3-1.5-1.6-2.2-3.9-1.8-6.1.2-1.6 1-3 2.3-3.9 1.7-1.2 4.2-1.8 7.6-1.8 2.2 0 4.2.3 5.5.5l.4.1.3-.3c4-4.6 4-10.8 3.3-15.2l-.2-2-1 1.5C20.4 52.3 14.5 56 7.5 57c-.4.1-.8.1-1.2.1-3.5 0-5.8-2.3-6.1-4.5-.2-1.1.2-2.3.9-3.1.9-1 2.1-1.6 3.5-1.7 4.9-.7 11.3-7.2 15.6-15.8 4-7.8 15.8-8.2 18.2-8.2m.4 2.6h-.3c-2.1 0-12.6.3-15.9 6.8C18.9 40.5 12.2 49.4 5 50.4c-1.1.2-1.9.6-2.1 1.2v.2c-.1.6.1 1.1.5 1.5.7.8 1.8 1.3 2.9 1.3.3 0 .6 0 .9-.1 7.5-1.1 13.6-5.8 18.1-14 .2-.3.4-.5.7-.6.2-.1.3-.1.5-.1.5 0 1 .3 1.2.8.2.4 3.7 10 .1 18.2l-.5 1.1 1.2-.1h.8c2.1 0 4.2.4 6.2 1.2 3.4 1.1 6.9 1.6 10.5 1.6h.9c2.5 0 4.8-.1 6.8-.3l1.3-.1-.8-1.1c-5.4-7.6-2.7-19.9-2.6-20.4.1-.4.3-.7.7-.9.2-.1.4-.2.6-.2.5 0 .9.3 1.2.7 4.8 8.8 11.5 14.7 18.1 15.6.3.1.6.1.9.1 1.1 0 2.2-.5 2.9-1.3.4-.4.6-1 .5-1.5V53c-.2-.6-1-1-2.1-1.2-6.5-.9-13.3-10.3-17.6-18.8-2.7-5.4-11.6-6.8-16.4-6.8H39.1c-.1.2-.2.2-.3.2m20.6 46.9c6.5 0 8.8-1.4 9.6-2.6.6-.9.7-2.2 0-3.9-.6-1.7-2.6-2.4-6.6-2.4-2 0-4.2.2-6.7.4-2.8.2-5.9.4-9 .4h-.9c-3.9 0-7.7-.6-11.3-1.8-1.7-.6-3.5-1-5.3-1-2.8 0-4.3 1.2-4.4 2.3-.1.9.6 1.8 2 2.2 1.2.4 2.3.8 3.3 1.1 3.4 1.3 7 2.3 10.6 3 6.1 1.3 12.4 2.1 18.7 2.3m-38.9.1c2.8 0 6.1-.2 10.9-.6l3.6-.3-3.5-1.2c-.9-.3-1.7-.6-2.4-.9H29c-1.1-.4-2-.7-3.1-1-2.4-.7-3.9-2.5-3.9-4.6v-.7l-.7-.1c-1.1-.2-2.9-.4-4.7-.4-2.8 0-4.9.4-6 1.3-.7.5-1.2 1.3-1.3 2.1-.3 2.4.4 3.4 1 4 1.7 1.7 4.8 2.4 10.2 2.4"
      />
    </svg>
  );
};
