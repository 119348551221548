import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'gatsby-plugin-react-i18next';
import axios, { AxiosError, AxiosResponse } from 'axios';
import slugify from 'slugify';
import 'bootstrap/js/dist/carousel';

/** Root */
import { asyncWrap } from '@root/util';

type Attachment = {
  url: string;
  height: number;
  width: number;
};

type Message = {
  attachments: Attachment[];
  content: string;
  author: string;
  timestamp: number;
  avatar: string;
};

type State = {
  messageList: Message[];
};

class TestimonialSectionComponent extends Component<WithTranslation, State> {
  private readonly maxChars = 897;

  constructor(props: Readonly<WithTranslation>) {
    super(props);

    this.state = { messageList: [] };
  }

  componentDidMount(): void {
    this.fetchTestimonialList();
  }

  private async fetchTestimonialList(): Promise<void> {
    const requestGetTransactions = await asyncWrap<any, AxiosError>(
      axios.get<any>(`${process.env.GATSBY_API_URL}/customer/testimonials`),
    );

    if (requestGetTransactions.error) {
      return;
    }

    const { data: response } =
      requestGetTransactions.result as AxiosResponse<any>;

    this.setState({ messageList: response.data });
  }

  private renderSlider(): JSX.Element {
    const { messageList } = this.state;
    const { t } = this.props;

    return (
      <div className="col-sm-12">
        <div
          id="carouselTestimonial"
          className="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {messageList.map((message: Message, index: number) => (
              <div
                className={`carousel-item${!index ? ' active' : ''}`}
                key={message.timestamp}
              >
                <div className="row d-flex testimonial-height justify-content-center align-items-center">
                  <div className="col-sm-6">
                    <figure className="text-center">
                      <blockquote className="blockquote">
                        <p>
                          {message.content.length > this.maxChars
                            ? `${message.content.substr(0, this.maxChars)}...`
                            : message.content}
                        </p>
                      </blockquote>
                      <figcaption className="blockquote-footer mt-2">
                        <img
                          src={message.avatar}
                          className="rounded me-2"
                          height="45px"
                        />
                        <span>{message.author} - </span>
                        <span>
                          {new Date(message.timestamp).toLocaleDateString()}
                        </span>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselTestimonial"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselTestimonial"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <p className="text-center">
          <span>{t('home.testimonial.discordTestimonials')}</span>
          <a
            href="https://discord.gg/dkkD3XXdFY"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none text-primary"
          >
            {t('home.testimonial.joinUs')}
          </a>
        </p>
      </div>
    );
  }

  render(): JSX.Element | null {
    const { messageList } = this.state;
    const { t } = this.props;
    const id = slugify(t('navbar.testimonials').toLowerCase());

    return (
      <section
        id={id}
        className="row m-0 home-section fs-section bg-custom-grey pt-5 pb-5"
      >
        <h2 className="fw-bold title-section text-center text-primary mb-4">
          {t('navbar.testimonials')}
        </h2>
        {!messageList.length ? null : this.renderSlider()}
      </section>
    );
  }
}

const TestimonialSection = withTranslation()(TestimonialSectionComponent);

export default TestimonialSection;
