import axios, { AxiosError, AxiosInstance } from 'axios';
import { useTranslation } from 'gatsby-plugin-react-i18next';

/** Models */
import { BackResponse } from '@models/back-response.model';

export abstract class HttpClient {
  protected axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.GATSBY_API_URL,
    });
  }

  protected getError(error: AxiosError<BackResponse<any>>): BackResponse<any> {
    if (!error.response) {
      return {
        result: false,
        message: useTranslation().t('global.somethingWentWrong'),
      };
    }

    return error.response.data;
  }
}
