import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const CommunitySection = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section className="community-section fs-section">
      <div className="row m-0 d-flex justify-content-center">
        <div className="col-sm-5 d-flex justify-content-center mt-5">
          <section>
            <h2 className="fw-bold title-section text-primary mb-4">
              {t('home.community.title')}
            </h2>
            <p className="mb-0">
              <strong>{t('home.community.joinDiscord1')}</strong>
              <span>, {t('home.community.joinDiscord2')}</span>
            </p>
            <p>
              <span>{t('home.community.whali1')} </span>
              <strong>{t('home.community.whali2')}</strong>
              <span> {t('home.community.whali3')}</span>
            </p>
          </section>
        </div>
        <div className="col-sm-4 d-flex justify-content-center mt-0 mt-sm-5 mb-5 mb-sm-0">
          <a
            href={process.env.GATSBY_DISCORD_INVITE}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="img-fluid"
              src="https://discord.com/api/guilds/673820717606764544/widget.png?style=banner4"
              alt={t('home.community.joinDiscord1')}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CommunitySection;
