import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

const defaultWidth = 128;
const defaultHeight = 128;

export const SimplicitySVG = (props: Props): JSX.Element => {
  const { width, height, className } = props;
  const appliedWith = width ? width : defaultWidth;
  const appliedHeight = height ? height : defaultHeight;
  const appliedClassName = className ? className : '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={appliedWith}
      height={appliedHeight}
      viewBox="0 0 89 89"
      className={appliedClassName}
    >
      <g fill="#2E2E2E">
        <path d="M1.127 43.441a4.5 4.5 0 00-1.091 3.545l.864 6.85a36.931 36.931 0 0010.536 21.477 1.483 1.483 0 00-.406 1.01v11.973a1.5 1.5 0 001.5 1.5h18.904a1.5 1.5 0 001.5-1.5V76.324a1.5 1.5 0 00-1.5-1.5h-.079v-2.991a16.485 16.485 0 00-6.372-12.958l-2.19-4.329a3.983 3.983 0 00-6.627-.715 3.907 3.907 0 00-.81 3.259l.922 4.562a1.536 1.536 0 00.132.379l.169.335a23.746 23.746 0 01-6.935-13.162l-.6-3.533a4.568 4.568 0 00-7.923-2.23zm28.81 34.38v8.981H14.024V77.82zM6.1 46.168l.6 3.528a26.822 26.822 0 0013.341 18.95 1.5 1.5 0 002.072-1.979l-2.937-5.808-.883-4.363a.912.912 0 01.193-.771.964.964 0 01.763-.358.983.983 0 01.874.53l2.346 4.64a1.5 1.5 0 00.449.528 13.472 13.472 0 015.443 10.767v2.994H15.268a34 34 0 01-11.4-21.365l-.867-6.857a1.5 1.5 0 01.367-1.186 1.575 1.575 0 012.724.748z" />
        <path d="M56.88 88.296a1.5 1.5 0 001.5 1.5h18.9a1.5 1.5 0 001.5-1.5V76.324a1.483 1.483 0 00-.406-1.01 36.931 36.931 0 0010.539-21.478l.867-6.857a4.5 4.5 0 00-1.091-3.539 4.568 4.568 0 00-7.923 2.23l-.6 3.533a23.746 23.746 0 01-6.935 13.162l.169-.335a1.536 1.536 0 00.132-.379l.922-4.562a3.907 3.907 0 00-.81-3.259 3.985 3.985 0 00-6.628.715l-2.188 4.329a16.485 16.485 0 00-6.372 12.958v2.994h-.076a1.5 1.5 0 00-1.5 1.5zm4.57-16.465a13.472 13.472 0 015.45-10.775 1.5 1.5 0 00.449-.528l2.344-4.64a.986.986 0 01.871-.519.964.964 0 01.763.358.912.912 0 01.193.771l-.883 4.363-2.937 5.808a1.5 1.5 0 002.072 1.979 26.822 26.822 0 0013.346-18.952l.6-3.533a1.575 1.575 0 012.724-.748 1.5 1.5 0 01.367 1.186l-.867 6.857a34 34 0 01-11.4 21.365H61.45zm-1.576 5.987h15.913v8.981H59.874z" />
        <path d="M31.997 5.695l-1.206-2.739c-.951.421-1.892.885-2.8 1.382l1.44 2.625a33.212 33.212 0 012.566-1.268z" />
        <path d="M42.877.043c-1.039.06-2.085.168-3.107.319l.437 2.961a35.76 35.76 0 012.844-.3z" />
        <path d="M36.7.956a35.105 35.105 0 00-2.994.868l.961 2.844c.9-.3 1.822-.572 2.744-.795z" />
        <path d="M49.119.238A34.1 34.1 0 0046.001 0l-.093 2.989c.952.027 1.91.1 2.844.214z" />
        <path d="M7.484 40.399h2.994c0-.964.033-1.934.1-2.886l-2.986-.21a44.32 44.32 0 00-.108 3.096z" />
        <path d="M22.482 12.003l-2.045-2.187c-.756.705-1.5 1.45-2.188 2.215l2.208 2.023a38.046 38.046 0 012.025-2.051z" />
        <path d="M14.429 16.938l2.5 1.647c.528-.8 1.093-1.587 1.678-2.34l-2.364-1.832a39.548 39.548 0 00-1.814 2.525z" />
        <path d="M7.918 34.223l2.965.421c.133-.949.3-1.9.507-2.844l-2.926-.635a44.019 44.019 0 00-.546 3.058z" />
        <path d="M9.229 28.163l2.871.843c.272-.922.579-1.843.913-2.735l-2.8-1.048a40.215 40.215 0 00-.984 2.94z" />
        <path d="M11.409 22.36l2.718 1.257c.4-.871.841-1.735 1.305-2.567l-2.614-1.458c-.5.9-.975 1.829-1.409 2.768z" />
        <path d="M55.234 1.538c-1-.308-2.015-.575-3.027-.789l-.624 2.926c.924.2 1.855.44 2.766.721z" />
        <path d="M78.227 30.954c.223.933.415 1.883.57 2.829l2.953-.485a43.449 43.449 0 00-.611-3.039z" />
        <path d="M82.318 39.462a44.01 44.01 0 00-.175-3.091l-2.98.274c.087.949.142 1.917.162 2.881z" />
        <path d="M70.667 15.575c.6.739 1.187 1.512 1.729 2.295l2.461-1.707a39.995 39.995 0 00-1.871-2.483z" />
        <path d="M76.489 25.463c.356.888.684 1.8.974 2.714l2.853-.907a42.98 42.98 0 00-1.048-2.92z" />
        <path d="M22.798 7.787l1.861 2.344c.748-.6 1.533-1.163 2.319-1.685l-1.655-2.494a36.22 36.22 0 00-2.525 1.835z" />
        <path d="M66.698 11.428c.707.629 1.4 1.3 2.066 2l2.164-2.064a38.713 38.713 0 00-2.245-2.161z" />
        <path d="M73.945 20.293c.486.829.945 1.682 1.364 2.545l2.694-1.313a39.153 39.153 0 00-1.47-2.736z" />
        <path d="M66.279 7.22a38.207 38.207 0 00-2.566-1.771l-1.594 2.536c.8.5 1.591 1.048 2.361 1.627z" />
        <path d="M61.004 3.906a35.151 35.151 0 00-2.844-1.31l-1.13 2.777a32.2 32.2 0 012.6 1.2z" />
      </g>
      <g fill="#2E2E2E">
        <path d="M44.903 56.551a19.959 19.959 0 1119.959-19.959 20.018 20.018 0 01-19.959 19.959zm0-36.592a16.633 16.633 0 1016.633 16.633A16.682 16.682 0 0044.903 19.96z" />
        <path d="M53.22 31.602a4.845 4.845 0 00-3.327-4.657v-2a1.663 1.663 0 10-3.327 0v1.663h-1.663v-1.659a1.663 1.663 0 10-3.327 0v1.663H38.25a1.663 1.663 0 000 3.327h1.663v4.99H38.25a1.663 1.663 0 000 3.327h1.663v4.99H38.25a1.663 1.663 0 000 3.327h3.327v1.663a1.663 1.663 0 003.327 0v-1.664h1.663v1.663a1.663 1.663 0 003.327 0v-2a4.845 4.845 0 003.326-4.649v-1.667a4.945 4.945 0 00-1.333-3.327 4.945 4.945 0 001.331-3.327zm-9.98-1.663h4.99a1.571 1.571 0 011.663 1.663v1.664a1.571 1.571 0 01-1.663 1.663h-4.99zm6.653 11.647a1.571 1.571 0 01-1.663 1.663h-4.99v-4.99h4.99a1.571 1.571 0 011.663 1.663z" />
      </g>
    </svg>
  );
};
