/** Models */
import { Rates } from '@models/rates.model';

/** Services */
import { HttpClient } from './http-client';

export class ExchangeRatesService extends HttpClient {
  async getRates(): Promise<Rates | null> {
    try {
      const { data } = await this.axiosInstance.get('exchange-rates');

      return data as Rates;
    } catch (err) {
      return null;
    }
  }
}
