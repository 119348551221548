import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

const defaultWidth = 128;
const defaultHeight = 128;

export const TransparencySVG = (props: Props): JSX.Element => {
  const { width, height, className } = props;
  const appliedWith = width ? width : defaultWidth;
  const appliedHeight = height ? height : defaultHeight;
  const appliedClassName = className ? className : '';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={appliedWith}
      height={appliedHeight}
      viewBox="0 0 97.1 98.5"
      className={appliedClassName}
      fill="#2E2E2E"
    >
      <path d="M83.4 47.3H64.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h18.9c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.5-1.5zM83.4 35.9H53.3c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h30.2c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.6-1.5zM83.4 24.6H51.8c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h31.6c.8 0 1.5-.7 1.5-1.5s-.6-1.5-1.5-1.5z" />
      <path d="M95.4 16.8c0-.1-.1-.2-.1-.4v-.1c-.1-.1-.1-.2-.2-.3L82.3 3.4c-.1-.1-.2-.2-.3-.2h-.1c-.2-.1-.4-.2-.7-.2H29.1c-.8 0-1.5.7-1.5 1.5S28.3 6 29.1 6h50.6v11.2c0 .8.7 1.5 1.5 1.5h11.2v74.2H30.6V63.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v31c0 .8.7 1.5 1.5 1.5H94c.8 0 1.5-.7 1.5-1.5V17.1c-.1-.1-.1-.2-.1-.3zM82.7 8.1l3.8 3.8 3.8 3.8h-7.6V8.1z" />
      <path d="M39.1 51.4l5.3 5.3-3.3 3.3c-.7.7-.7 1.7 0 2.4l26.4 26.4c.3.3.8.5 1.2.5.5 0 .9-.1 1.2-.5l3.3-3.3 2.4-2.4 3.3-3.3c.3-.3.5-.8.5-1.2 0-.5-.2-.9-.5-1.2L52.5 51.1c-.7-.7-1.8-.7-2.4 0l-3.3 3.3-5.4-5.4c3.2-3.7 5.2-8.6 5.2-13.9 0-11.9-9.6-21.5-21.5-21.5S3.7 23.2 3.7 35s9.6 21.5 21.5 21.5c5.3 0 10.2-1.9 13.9-5.1zm12.2 3.3l24.1 24.1-6.6 6.6-24.1-24.3 6.6-6.4zM7.2 35.1c0-9.9 8.1-18.1 18.1-18.1s18.1 8.1 18.1 18.1-8.1 18.1-18.1 18.1S7.2 45.1 7.2 35.1z" />
      <path d="M39.5 35.8c.2-.5.2-1 0-1.5-2.6-5.4-8.1-9-14.2-9s-11.6 3.5-14.2 9c-.2.5-.2 1 0 1.5 2.6 5.4 8.1 9 14.2 9s11.6-3.6 14.2-9zm-24.9-.7c2.1-3.8 6.3-6.3 10.7-6.3s8.6 2.5 10.7 6.3c-2.1 3.8-6.3 6.3-10.7 6.3s-8.6-2.4-10.7-6.3z" />
      <path d="M29.7 35.1c0-2.5-2-4.4-4.4-4.4s-4.4 2-4.4 4.4c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4zm-5.9 0c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4z" />
    </svg>
  );
};
