import React from 'react';

const ArrowDown = (): JSX.Element => {
  return (
    <svg
      height="45"
      width="45"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75 75"
    >
      <ellipse
        cx="37.5"
        cy="37.13"
        rx="35"
        ry="34.63"
        fill="none"
        stroke="#F9AC12"
        strokeWidth="5"
      />
      <path
        d="M58.75 29.75l-19.86 20.6a1.93 1.93 0 01-2.78 0l-19.86-20.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="#F9AC12"
        strokeWidth="5"
      />
    </svg>
  );
};

export default ArrowDown;
